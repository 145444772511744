<template>
    <div class="row journal-line border-rad-8">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="row w-100 pe-lg-0 me-1 py-2">
                <div class="col-lg-3 col-12 mt-1">
                    <label class="align-middle">Product</label>
                    <AsyncSelect
                        placeholder="Select Product"
                        v-model="selectedProduct"
                        :api-service="fetchProductList"
                        :additional-query="{ excluded_type: 'services', warehouse_id: warehouseId }"
                        :format-label="option => option.text"
                        label="text"
                        ref="productField"
                    />
                </div>
                <div class="col-lg-3 col-6 mt-1">
                    <label class="align-middle">Stock in hand</label>
                    <input
                        :value="item.stock_in_hand"
                        readonly
                        class="form-control text-end"
                        placeholder="Stock In Hand"
                    >
                </div>
                <div class="col-lg-3 col-6 mt-1">
                    <label class="align-middle">Adjustment Qty</label>
                    <input
                        v-model="item.adjustment_qty"
                        type="number"
                        class="form-control text-end"
                        placeholder="Qty Adjustment"
                        :disabled="item.product_id === null"
                        @keyup="calculateAdjustment()"
                    >
                </div>
                <div class="col-lg-3 col-6 mt-1">
                    <label class="align-middle">Adjusted Stock</label>
                    <input
                        v-model="item.adjusted_stock"
                        type="number"
                        class="form-control text-end"
                        placeholder="Adjusted Stock"
                        :disabled="item.product_id === null"
                        @keyup="calculateAdjustment('stock')"
                    >
                </div>
              <div class="col-lg-3 col-6 mt-1">
                    <label class="align-middle">Rate</label>
                    <input
                        v-model="item.average_price"
                        type="number"
                        class="form-control text-end"
                        placeholder="Rate"
                        :disabled="item.product_id === null"
                    >
                </div>
              <div class="col-lg-3 col-6 mt-1">
                    <label class="align-middle">Amount</label>
                    <input
                        :value="(item.adjustment_qty * item.average_price).toFixed(2)"
                        readonly
                        class="form-control text-end"
                        placeholder="Stock In Hand"
                    >
                </div>
                <div class="col-lg-12 col-12 mt-1">
                    <label class="align-middle">Description</label>
                    <textarea
                      v-model="item.note"
                      placeholder="Description"
                      class="form-control"
                      rows="1"
                    />
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25
                disabled"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-x cursor-pointer font-medium-3">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>

import {computed, inject, onMounted, watch, ref} from "vue";
import handleStock from "@/services/modules/stock";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import handlePurchaseAndSales    from "@/services/modules/purchase";

const {fetchProductStock} = handleStock()
const {fetchProductList} = handlePurchaseAndSales();
const $route = useRoute();
const $store = useStore();
const showError = inject('showError')

const $props = defineProps({
    item    : {
        type: Object,
        default: {
            product_id : null,
            stock_in_hand : 0,
            average_price : 0,
            adjustment_qty : null,
            adjusted_stock : null,
            note : null,
        }
    },
    index   : {
        type: Number
    },
    products: {
        type: Array
    },
    vatTypes: {
        type: Array
    },
    warehouseId: {
        type: Number,
        default: null
    },
    resetAdjQty: {
        type: Boolean,
        default: true
    }
});
const selectedProduct = ref(null);

//computed
const selectedProductId = computed(() => $props.item.product_id)
const selectedWarehouseId = computed(() => $props.warehouseId)

//watchers

watch(selectedProduct, (newValue) => {
    $props.item.product_id = newValue ? newValue.id : null;
})

watch(selectedWarehouseId, (oldValue) => {
    if(oldValue !== null) {
        selectedProduct.value = null;
        $props.item.product_id = null;
        $props.item.stock_in_hand = 0
        $props.item.average_price = 0
    }
})

watch(selectedProductId, async (newValue, oldValue) => {
    $store.state.loading = true;
    let query = `?company_id=${$route.params.companyId}&product_id=${newValue}`;
    if($props.resetAdjQty) {
        $props.item.stock_in_hand  = 0;
        $props.item.average_price  = 0;
        $props.item.adjusted_stock = null;
        $props.item.adjustment_qty = null;
    }
    if ($props.warehouseId) {
        query += `&warehouse_id=${$props.warehouseId}`;
    }
    if (newValue !== null && $props.resetAdjQty) {
        await fetchProductStock(query).then(res => {
            if (res.data) {
                $props.item.stock_in_hand = res.data.product_stock;
                $props.item.average_price = res.data.average_price;
            }
        })
    }
    $store.state.loading = false;
})

const calculateAdjustment = (type = 'qty') => {
    if(type === 'stock' && $props.item.adjusted_stock && $props.item.adjusted_stock > 0) {
        $props.item.adjustment_qty = ($props.item.adjusted_stock - parseFloat($props.item.stock_in_hand)).toFixed(4);
        return;
    }
    if(type === 'qty' && $props.item.adjustment_qty && ($props.item.stock_in_hand + $props.item.adjustment_qty) >= 0) {
        $props.item.adjusted_stock = ($props.item.stock_in_hand + parseFloat($props.item.adjustment_qty)).toFixed(4)
        return;
    }

    if ((type === 'stock' && !$props.item.adjusted_stock) || (type === 'qty' && ! $props.item.adjustment_qty)) {
        return;
    }

    $props.item.adjusted_stock = 0;
    $props.item.adjustment_qty = (- parseFloat($props.item.stock_in_hand)).toFixed(4);
    showError("Adjusted stock cannot be less than 0")
}

onMounted(() => {
    if ($props.item.product_id) {
        selectedProduct.value = {
           id: $props.item.product_id,
           text: $props.item.product_name
        }
    }
})


</script>
<style scoped>
.journal-line {
    border: 1px solid #f0f2f5;
}

.journal-line:hover {
    background: #f0f2f5;
}
</style>
